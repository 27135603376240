
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import { ICliente, IClienteEndereco } from '@/models/Entidades/ICliente';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import ServicoCliente from '@/servicos/ServicoCliente';
import ServicoUtilitario from '@/servicos/ServicoUtilitario';
import { IResposta } from '@/core/models/IResposta';
import Icone from '@/core/components/UI/Icone.vue';
import ComboTipoPessoa from '@/components/ComboTipoPessoa.vue';
import CampoCep from '@/core/components/UI/CampoCep.vue';
import CampoCpf from '@/core/components/UI/CampoCpf.vue';
import CampoCnpj from '@/core/components/UI/CampoCnpj.vue';
import CampoTelefone from '@/core/components/UI/CampoTelefone.vue';
import ComboCidade from '@/components/ComboCidade.vue';
import CampoEmail from '@/core/components/UI/CampoEmail.vue';
import CampoSenha from '@/core/components/UI/CampoSenha.vue';
import ComboGenero from '@/components/ComboGenero.vue';
import SelecionarData from '@/core/components/UI/SelecionarData.vue';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ETipoPessoa } from '@/models/Enumeradores/ETipoPessoa';

export default defineComponent({
  name: 'ClienteCadastro',
  components: {
    ComunicacaoApi,
    Icone,
    ComboTipoPessoa,
    CampoCep,
    CampoCpf,
    CampoCnpj,
    CampoTelefone,
    ComboCidade,
    CampoEmail,
    CampoSenha,
    SelecionarData,
    ComboGenero,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarRespostaRedirecionamento, obterCodigoRota,
    } = useAppBase();
    const servicoCliente = new ServicoCliente();
    const servicoUtilitario = new ServicoUtilitario();
    const state = reactive({
      cliente: {} as ICliente,
      chaveEndereco: '0',
      cep: '',
      cpfCnpj: '',
    });

    function adicionarEndereco() {
      const endereco:IClienteEndereco = {} as IClienteEndereco;
      endereco.codigo = 0;
      endereco.principal = false;
      endereco.identificacao = '';
      endereco.cep = '';
      endereco.numero = '';
      endereco.endereco = '';
      endereco.complemento = '';
      endereco.bairro = '';
      state.cliente.enderecos.push(endereco);
      state.chaveEndereco = (state.cliente.enderecos.length - 1).toString();
    }

    function removerEndereco(index:number) {
      state.cliente.enderecos.splice(index, 1);
    }
    function limparDados() {
      state.cliente = {} as ICliente;
      state.cliente.codigo = 0;
      state.cliente.status = true;
      state.cliente.tipoPessoa = 1;
      state.cliente.genero = 1;
      state.cliente.enderecos = [];
      adicionarEndereco();
      state.cliente.enderecos[0].principal = true;
      state.cpfCnpj = '';
      state.cep = '';
    }

    async function obterCliente(codigo:number) {
      appBase.carregando = true;
      state.cliente = await servicoCliente.obter(codigo);
      state.cpfCnpj = state.cliente.cpfCnpj;
      if (!UtilitarioGeral.validaLista(state.cliente.enderecos)) {
        state.cliente.enderecos = [];
      }
      appBase.carregando = false;
    }

    onBeforeMount(async () => {
      limparDados();
      const codigoCliente = obterCodigoRota();
      if (codigoCliente > 0) {
        await obterCliente(codigoCliente);
      }
    });

    async function salvar() {
      appBase.resposta = {} as IResposta;

      if (state.cliente.tipoPessoa === ETipoPessoa.Fisica) {
        state.cliente.identificacao = state.cliente.nomeRazaoSocial;
      }
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as informações do Cliente.');
      if (state.cliente.codigo === 0) {
        appBase.resposta = await servicoCliente.incluir(state.cliente);
      } else {
        appBase.resposta = await servicoCliente.atualizar(state.cliente);
      }

      apresentarRespostaRedirecionamento('Clientes');
    }

    async function consultaCep(index:number) {
      if (UtilitarioGeral.valorValido(state.cliente.enderecos[index].cep) && UtilitarioGeral.validaValorComLimiteCaracteres(state.cliente.enderecos[index].cep, 8)) {
        if (state.cliente.enderecos[index].cep !== state.cep) {
          const retornoConsultaCep = await servicoUtilitario.consultaCep(state.cliente.enderecos[index].cep);
          if (retornoConsultaCep !== undefined) {
            state.cliente.enderecos[index].endereco = retornoConsultaCep.logradouro;
            state.cliente.enderecos[index].complemento = retornoConsultaCep.complemento;
            state.cliente.enderecos[index].bairro = retornoConsultaCep.bairro;
            state.cliente.enderecos[index].codigoCidade = retornoConsultaCep.codigoCidade;
          }
        }
      }
    }

    async function consultaCnpj() {
      if (UtilitarioGeral.valorValido(state.cliente.cpfCnpj) && UtilitarioGeral.validaValorComLimiteCaracteres(state.cliente.cpfCnpj, 14)) {
        if (state.cliente.cpfCnpj !== state.cpfCnpj) {
          const retornoConsultaCnpj = await servicoUtilitario.consultaCnpj(state.cliente.cpfCnpj);
          if (retornoConsultaCnpj !== undefined && UtilitarioGeral.valorValido(retornoConsultaCnpj.razaoSocial)) {
            state.cliente.nomeRazaoSocial = retornoConsultaCnpj.razaoSocial;
            state.cliente.email = retornoConsultaCnpj.contato.email;
            state.cliente.telefone = retornoConsultaCnpj.contato.telefonePrincipal;

            if (UtilitarioGeral.validaLista(state.cliente.enderecos)) {
              if (!UtilitarioGeral.valorValido(state.cliente.enderecos[0].endereco)) {
                state.cliente.enderecos[0].cep = retornoConsultaCnpj.endereco.cep;
                state.cliente.enderecos[0].numero = retornoConsultaCnpj.endereco.numero;
                state.cliente.enderecos[0].endereco = retornoConsultaCnpj.endereco.logadouro;
                state.cliente.enderecos[0].complemento = retornoConsultaCnpj.endereco.complemento;
                state.cliente.enderecos[0].bairro = retornoConsultaCnpj.endereco.bairro;
                state.cliente.enderecos[0].codigoCidade = retornoConsultaCnpj.endereco.codigoCidade;
              }
            } else {
              const endereco:IClienteEndereco = {} as IClienteEndereco;
              endereco.codigo = 0;
              endereco.principal = true;
              endereco.identificacao = 'PRINCIPAL';
              endereco.cep = retornoConsultaCnpj.endereco.cep;
              endereco.numero = retornoConsultaCnpj.endereco.numero;
              endereco.endereco = retornoConsultaCnpj.endereco.logadouro;
              endereco.complemento = retornoConsultaCnpj.endereco.complemento;
              endereco.bairro = retornoConsultaCnpj.endereco.bairro;
              endereco.codigoCidade = retornoConsultaCnpj.endereco.codigoCidade;
              state.cliente.enderecos.push(endereco);
              state.chaveEndereco = (state.cliente.enderecos.length - 1).toString();
            }
          }
        }
      }
    }

    return {
      appBase,
      state,
      salvar,
      consultaCep,
      consultaCnpj,
      adicionarEndereco,
      removerEndereco,
      UtilitarioGeral,
    };
  },
});
